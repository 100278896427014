import Routing from "./routes";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import "./App.css";

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const language = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
  };

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#f07d00",
          },
        }}
      >
        <Routing language={language} changeLanguage={changeLanguage} />
      </ConfigProvider>
    </div>
  );
}

export default App;
